module.exports = [{
      plugin: require('/home/bw/gatsby-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/home/bw/gatsby-blog/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/bw/gatsby-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-40261983-1"},
    },{
      plugin: require('/home/bw/gatsby-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
